<template>
  <section class="new-event relative">
    <div class="block md:flex justify-between">
      <h1 class="h1-Alternative uppercase text-center mb-3 md:mb-0">New Event</h1>
    </div>

    <div class="mt-5 lg:mt-10">
      <div class="bg-white dark:bg-dark-bgSecondary border-t-2 border-grey-light dark:border-dark-textSecondary">
        <div class="flex flex-col min-w-0 break-words w-full">
          <div class="flex-auto dark:text-dark-textPrimary">
            <Form v-slot="{ errors }" :validation-schema="schema" class="flex flex-col font-fs-elliot-pro py-5 mb-10 md:mb-0" @submit="onSubmit">
              <div class="flex items-center flex-col md:flex-row md:mb-7 lg:mb-8">
                <div class="w-full md:w-1/3 lg:w-1/6">
                  <label for="event-id" class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold"> Custom Event ID: </label>
                </div>
                <div class="w-full md:w-2/3 mt-2 md:mt-0">
                  <Field
                    id="event-id"
                    v-model="payload.id"
                    name="id"
                    type="text"
                    class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-lg focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
                  />
                </div>
              </div>
              <div class="flex items-center flex-col md:flex-row md:mb-7 lg:mb-8">
                <div class="w-full md:w-1/3 lg:w-1/6">
                  <label for="event-name" class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold"> Name: </label>
                </div>
                <div class="w-full md:w-2/3 mt-2 md:mt-0">
                  <Field
                    id="event-name"
                    v-model="payload.name"
                    name="name"
                    type="text"
                    :class="{
                      'text-danger border border-danger': errors.name,
                    }"
                    class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-lg focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
                  />
                  <p class="text-danger dark:text-danger text-base italic">
                    {{ errors.name }}
                  </p>
                </div>
              </div>

              <div class="flex items-center flex-col md:flex-row md:mb-7 lg:mb-8">
                <div class="w-full md:w-1/3 lg:w-1/6">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Event Template: </label>
                </div>
                <div class="w-full md:w-2/3 mt-2 md:mt-0">
                  <ComboBox
                    class="w-full"
                    v-model="data.eventTemplateId"
                    :options="
                      data.eventTemplateList.map(item => ({
                        label: item.name,
                        value: item.id,
                      }))
                    "
                  />
                </div>
              </div>

              <div class="flex items-center flex-col md:flex-row md:mb-7 lg:mb-8">
                <div class="w-full md:w-1/3 lg:w-1/6">
                  <label for="event-name" class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold"> Page Title: </label>
                </div>
                <div class="w-full md:w-2/3 mt-2 md:mt-0">
                  <Field
                    id="event-title"
                    v-model="payload.title"
                    name="title"
                    type="text"
                    :class="{
                      'text-danger border border-danger': errors.title,
                    }"
                    class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-lg focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
                  />
                  <p class="text-danger dark:text-danger text-base italic">
                    {{ errors.title }}
                  </p>
                </div>
              </div>

              <div class="flex items-center flex-col md:flex-row md:mb-7 lg:mb-8">
                <div class="w-full md:w-1/3 lg:w-1/6">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold"> Date: </label>
                </div>
                <div class="w-full md:w-2/3 mt-2 md:mt-0">
                  <div class="inline-flex w-full">
                    <div class="w-20 md:w-1/2">
                      <Field
                        v-model="data.date"
                        name="eventDate"
                        type="date"
                        :class="{
                          'text-danger border border-danger': errors.eventDate,
                        }"
                        class="appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-lg text-black-1 dark:text-dark-textPrimary text-center md:text-left border border-grey-mid focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3 mr-4 md:mr-12"
                      />
                      <p class="text-danger dark:text-danger text-base italic mr-1 md:mr-14 lg:mr-28">
                        {{ errors.eventDate }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex items-center flex-col md:flex-row md:mb-7 lg:mb-8">
                <div class="w-full md:w-1/3 lg:w-1/6">
                  <label for="event-welcome-message" class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold"> Welcome Message: </label>
                </div>
                <div class="w-full md:w-2/3 mt-2 md:mt-0">
                  <Field
                    id="event-welcome-message"
                    v-model="payload.welcomeMessage"
                    as="textarea"
                    name="welcomeMessage"
                    rows="5"
                    :class="{
                      'text-danger border border-danger': errors.welcomeMessage,
                    }"
                    class="w-full text-xs md:text-lg text-black-1 dark:text-dark-textPrimary bg-off-white-1 dark:bg-dark-bgPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded p-2 md:p-3"
                  />
                  <p class="text-danger dark:text-danger text-base italic">
                    {{ errors.name }}
                  </p>
                </div>
              </div>

              <div class="flex items-center flex-col md:flex-row md:mb-7 lg:mb-8">
                <div class="w-full md:w-1/3 lg:w-1/6">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Login Mode: </label>
                </div>
                <div class="w-full md:w-2/3 mt-2 md:mt-0">
                  <ComboBox
                    class="w-full"
                    v-model="data.loginMode"
                    :options="[
                      { label: 'Public', value: 'PUBLIC' },
                      { label: 'Pre-registered Viewer Logins', value: 'CUSTOM_LIST' },
                    ]"
                  />
                </div>
              </div>

              <div class="flex items-start flex-col md:flex-row md:mb-7 lg:mb-8">
                <div class="w-1/3 lg:w-1/6">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold"> Password Protection: </label>
                </div>
                <div class="pl-2 md:pl-0 w-2/3 flex">
                  <Switch v-model="settings.isProtect" />
                  <div v-if="settings.isProtect" class="flex flex-col w-full ml-2">
                    <Field
                      v-model="settings.password"
                      type="text"
                      name="password"
                      rows="5"
                      :class="{
                        'text-danger border border-danger': errors['password'],
                      }"
                      class="w-full text-xs md:text-base text-black-1 dark:text-dark-textPrimary bg-off-white-1 dark:bg-dark-bgPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded p-2 md:p-3"
                    />
                    <p class="text-danger dark:text-danger text-base italic">
                      {{ errors["password"] }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="flex items-start flex-col md:flex-row md:mb-7 lg:mb-8">
                <div class="w-1/3 lg:w-1/6">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Paid event: </label>
                </div>
                <div class="pl-2 md:pl-0 w-2/3 flex">
                  <Switch v-model="data.isPaidEvent" />
                  <template v-if="data.isPaidEvent">
                    <Field
                      v-model="data.price"
                      type="text"
                      name="price"
                      rows="5"
                      :class="{
                        'text-danger border border-danger': errors['price'],
                      }"
                      class="w-full text-xs md:text-base text-black-1 dark:text-dark-textPrimary bg-off-white-1 dark:bg-dark-bgPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded p-2 md:p-3 ml-2"
                    />
                    <p class="text-danger dark:text-danger text-base italic">
                      {{ errors["price"] }}
                    </p>
                  </template>
                </div>
              </div>
              <div class="flex items-center flex-col md:flex-row md:mb-7 lg:mb-8">
                <div class="w-1/3 lg:w-1/6">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold"> Ticker Bar: </label>
                </div>
                <div class="pl-2 md:pl-0 w-2/3 flex items-center">
                  <Switch v-model="settings.bottomBar" />
                  <b class="text-xs md:text-base text-magenta font-montserrat font-semibold ml-3">
                    {{ settings.bottomBar ? "SHOW" : "HIDE" }}
                  </b>
                </div>
              </div>

              <div class="flex items-center flex-col md:flex-row md:mb-7 lg:mb-8">
                <div class="w-full md:w-1/3 lg:w-1/6">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold"> Streaming URL: </label>
                </div>
                <div class="w-full md:w-2/3 mt-2 md:mt-0">
                  <Field
                    name="youtubeURL"
                    v-model="payload.youtubeURL"
                    type="text"
                    :class="{
                      'text-danger border border-danger': errors['youtubeURL'],
                    }"
                    class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-lg text-black-1 dark:text-dark-textPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
                  />
                  <p class="text-danger dark:text-danger text-base italic">
                    {{ errors["youtubeURL"] }}
                  </p>
                </div>
              </div>

              <div class="flex items-center flex-col md:flex-row md:mb-7 lg:mb-8">
                <div class="w-full md:w-1/3 lg:w-1/6">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold"> Fundraising Site URL: </label>
                </div>
                <div class="w-full md:w-2/3 mt-2 md:mt-0">
                  <div class="grid grid-cols-1 gap-4">
                    <!-- <div>
                      <Field
                        name="fundraisingSiteUrl.name"
                        v-model="payload.fundraisingSiteUrl.name"
                        type="text"
                        :class="{
                          'text-danger border border-danger':
                            errors['fundraisingSiteUrl.name'],
                        }"
                        class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-lg text-black-1 dark:text-dark-textPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
                      />
                      <p class="text-danger dark:text-danger text-base italic">
                        {{ errors["fundraisingSiteUrl.name"] }}
                      </p>
                    </div> -->

                    <div>
                      <Field
                        name="fundraisingSiteUrl.url"
                        v-model="payload.fundraisingSiteUrl.url"
                        type="text"
                        :class="{
                          'text-danger border border-danger': errors['fundraisingSiteUrl.url'],
                        }"
                        class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-lg text-black-1 dark:text-dark-textPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
                      />
                      <p class="text-danger dark:text-danger text-base italic">
                        {{ errors["fundraisingSiteUrl.url"] }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex items-center flex-col md:flex-row md:mb-7 lg:mb-8">
                <div class="w-full mt-2 md:mt-0 md:w-1/3 lg:w-1/4 md:pt-0">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold"> Primary Logo: </label>
                </div>
                <div class="md:flex md:items-center w-2/3 mt-2 md:mt-0 md:w-auto lg:w-2/3">
                  <label
                    class="w-60 lg:w-60 h-20 lg:h-24 flex flex-col relative items-center bg-off-white-1 dark:bg-dark-bgPrimary text-black-1 dark:text-dark-textPrimary rounded-lg border border-grey-mid focus:ring-magenta focus:border-magenta cursor-pointer md:ml-0"
                  >
                    <template v-if="!isFile(filesUpload.charityLogo)">
                      <XLogo class="h-8 w-8 text-blue-light absolute inset-y-1/4 left-5 lg:left-1/4" />
                      <span class="uppercase font-bold absolute top-1/5 left-14 lg:left-2/5">
                        <p>Primary</p>
                        <p>Logo</p>
                      </span>
                    </template>
                    <img v-else :src="images.charityLogo" alt="Client Logo" class="h-full" />

                    <input name="charityLogo" type="file" class="hidden" v-on:change="handleChangeFilesUpload" accept="image/png, image/jpeg" />
                  </label>

                  <div class="flex" v-if="isFile(filesUpload.charityLogo)">
                    <button
                      type="button"
                      class="bg-transparent text-lg lg:text-xl text-magenta font-bold inline-flex items-center border-none overflow-hidden focus:outline-none p-2 lg:px-4"
                      @click="handleDeleteFilesUpload('charityLogo')"
                    >
                      <DeleteIcon class="w-6 h-6 mr-1 lg:mr-2" />
                      <span>Delete</span>
                    </button>

                    <!-- <button
                      type="button"
                      class="
                        bg-transparent
                        text-lg
                        lg:text-xl
                        text-magenta
                        font-bold
                        inline-flex
                        items-center
                        border-none
                        overflow-hidden
                        focus:outline-none
                        p-2
                        lg:px-4
                      "
                      @click="handleUploadFiles('charityLogo')"
                    >
                      <UploadIcon class="w-6 h-6 mr-1 lg:mr-2" />
                      <span>Replace</span>
                    </button> -->
                  </div>
                </div>
              </div>

              <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
                <div class="w-full mt-2 md:mt-0 md:w-1/3 lg:w-1/4 md:pt-0">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold"> Additional Logo(s): </label>
                </div>
                <div class="md:flex md:items-center w-2/3 md:w-auto lg:w-2/3 mt-2 md:mt-0">
                  <label
                    class="w-60 lg:w-60 h-20 lg:h-24 flex flex-col relative items-center bg-off-white-1 dark:bg-dark-bgPrimary text-black-1 dark:text-dark-textPrimary rounded-lg border border-grey-mid focus:ring-magenta focus:border-magenta cursor-pointer md:ml-0"
                    v-if="empty(images.sponsorLogo)"
                  >
                    <XLogo class="h-8 w-8 text-magenta absolute inset-y-1/4 left-5 lg:left-1/8" />
                    <span class="font-bold absolute top-1/5 left-14 lg:left-1/4">
                      <p>Logo</p>
                      Suggested: 300x60
                    </span>
                    <input name="sponsorLogo" type="file" class="hidden" @change="handleChangeFilesUpload" accept="image/png, image/jpeg" />
                  </label>
                  <div class="flex flex-wrap" v-else>
                    <label v-for="(image, index) in images.sponsorLogo" :key="index" class="relative mt-6 self-start cursor-pointer mr-10">
                      <div class="image-wrapper">
                        <img :src="image" alt="Additional Logo" class="h-20 lg:h-24" />
                      </div>
                      <DeleteIcon class="w-6 h-6 absolute top-0 -right-6" @click.prevent="handleDeleteFilesUpload('sponsorLogo', index)" />
                      <input name="sponsorLogo" type="file" class="hidden" @change="e => handleChangeFilesUpload(e, index)" accept="image/png, image/jpeg" />
                    </label>
                  </div>

                  <label v-if="!empty(images.sponsorLogo)" class="w-40 lg:w-60 h-20 lg:h-24 flex flex-col relative items-start cursor-pointer">
                    <div class="w-40 h-full absolute flex items-center text-lg lg:text-xl text-magenta font-bold">
                      <AddIcon class="h-6 w-6 mr-1 lg:mr-2" />
                      <span class="font-bold"> Add Another </span>
                    </div>
                    <input name="sponsorLogo" type="file" class="hidden" v-on:change="handleChangeFilesUpload" accept="image/png, image/jpeg" />
                  </label>
                </div>
              </div>

              <div class="flex items-center flex-col md:flex-row md:mb-7 lg:mb-8">
                <div class="w-full mt-2 md:mt-0 md:w-1/3 lg:w-1/4 md:pt-0">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold"> Background Image: </label>
                </div>
                <div class="md:flex md:items-center w-2/3 md:w-auto lg:w-2/3 mt-2 md:mt-0">
                  <label
                    :class="{ 'py-7': !isFile(filesUpload.backgroundImage) }"
                    class="w-60 lg:w-60 h-20 lg:h-24 flex flex-col relative items-center bg-off-white-1 dark:bg-dark-bgPrimary text-black-1 dark:text-dark-textPrimary rounded-lg border border-grey-mid focus:ring-magenta focus:border-magenta cursor-pointer md:ml-0"
                  >
                    <QRCodeLogo v-if="!isFile(filesUpload.backgroundImage)" class="h-8 w-8" />
                    <img v-else :src="images.backgroundImage" alt="Background Image" class="h-full" />
                    <input name="backgroundImage" type="file" class="hidden" v-on:change="handleChangeFilesUpload" accept="image/png, image/jpeg" />
                  </label>
                  <div class="flex" v-if="isFile(filesUpload.backgroundImage)">
                    <button
                      type="button"
                      class="bg-transparent text-lg lg:text-xl text-magenta font-bold inline-flex items-center border-none overflow-hidden focus:outline-none p-2 lg:px-4"
                      @click="handleDeleteFilesUpload('backgroundImage')"
                    >
                      <DeleteIcon class="w-6 h-6 mr-1 lg:mr-2" />
                      <span>Delete</span>
                    </button>
                  </div>
                </div>
              </div>

              <div class="flex flex-col md:flex-row mt-2 md:mt-0 md:mb-7 lg:mb-8">
                <div class="w-full md:w-1/3 lg:w-1/6">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold"> Primary color: </label>
                </div>
                <div class="md:flex md:items-center w-2/3 mt-2 md:mt-0 md:w-auto lg:w-2/3">
                  <div class="relative flex items-center">
                    <span class="absolute left-4 text-lg font-semibold text-grey-mid"># &nbsp; {{ payload.primaryColour.substring(1) }}</span>

                    <span class="absolute left-11.5 w-1px h-12 bg-grey-mid"></span>

                    <Field
                      v-model="payload.primaryColour"
                      name="primaryColor"
                      type="color"
                      class="h-12 w-52 md:w-60 text-lg text-left font-semibold appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-grey-dark dark:text-dark-textPrimary border border-grey-mid focus:border-magenta focus:outline-none focus:ring-1 focus:ring-magenta rounded p-0.5 pl-11.75 pr-1"
                    />
                  </div>
                </div>
              </div>

              <div class="flex flex-col md:flex-row mt-2 md:mt-0 md:mb-7 lg:mb-8">
                <div class="w-full md:w-1/3 lg:w-1/6">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold"> Secondary color: </label>
                </div>
                <div class="md:flex md:items-center w-2/3 mt-2 md:mt-0 md:w-auto lg:w-2/3">
                  <div class="relative flex items-center">
                    <span class="absolute left-4 text-lg font-semibold text-grey-mid"># &nbsp; {{ payload.secondaryColor.substring(1) }}</span>

                    <span class="absolute left-11.5 w-1px h-12 bg-grey-mid"></span>

                    <Field
                      v-model="payload.secondaryColor"
                      name="secondaryColor"
                      type="color"
                      class="h-12 w-52 md:w-60 text-lg text-left font-semibold appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-grey-dark dark:text-dark-textPrimary border border-grey-mid focus:border-magenta focus:outline-none focus:ring-1 focus:ring-magenta rounded p-0.5 pl-11.75 pr-1"
                    />
                  </div>
                </div>
              </div>

              <div class="flex flex-col md:flex-row mt-2 md:mt-0 md:mb-7 lg:mb-8">
                <div class="w-full md:w-1/3 lg:w-1/6">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold"> Font: </label>
                </div>
                <div class="md:flex md:items-center mt-2 md:mt-0 w-2/3 lg:w-2/3">
                  <ComboBox
                    class="w-full"
                    v-model="data.currentFont"
                    :options="[
                      { label: 'Local font', value: '' },
                      { label: 'Mulish', value: `'Mulish', sans-serif` },
                      { label: 'Inconsolata', value: `'Inconsolata', monospace` },
                      { label: 'Montserrat', value: `'Montserrat', sans-serif` },
                      { label: 'Noto Sans Mono', value: `'Noto Sans Mono', monospace` },
                      { label: 'Poppins', value: `'Poppins', sans-serif` },
                      { label: 'Roboto', value: `'Roboto', sans-serif` },
                      {
                        label: 'Open Sans',
                        value: `'Open Sans', sans-serif`,
                      },
                    ]"
                  />
                </div>
              </div>

              <div class="flex items-center flex-col md:flex-row md:mb-7 lg:mb-8">
                <div class="w-full md:w-1/3 lg:w-1/6">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold"> Custom css: </label>
                </div>
                <div class="w-full md:w-2/3 mt-2 md:mt-0">
                  <Field
                    v-model="payload.customCSS"
                    as="textarea"
                    name="customCSS"
                    rows="5"
                    class="w-full text-xs md:text-base text-black-1 dark:text-dark-textPrimary bg-off-white-1 dark:bg-dark-bgPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded p-2 md:p-3"
                  />
                </div>
              </div>

              <div class="flex items-center flex-col md:flex-row md:mb-7 lg:mb-8">
                <div class="w-full md:w-1/3 lg:w-1/6">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold"> Link 1: </label>
                </div>
                <div class="w-full md:w-2/3 mt-2 md:mt-0">
                  <div class="grid grid-cols-2 gap-4">
                    <div>
                      <Field
                        name="bidUrl.name"
                        v-model="payload.bidUrl.name"
                        type="text"
                        class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-lg text-black-1 dark:text-dark-textPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
                      />
                    </div>

                    <div>
                      <Field
                        name="bidUrl.url"
                        v-model="payload.bidUrl.url"
                        type="text"
                        :class="{
                          'text-danger border border-danger': errors['bidUrl.url'],
                        }"
                        class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-lg text-black-1 dark:text-dark-textPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
                      />
                      <p class="text-danger dark:text-danger text-base italic">
                        {{ errors["bidUrl.url"] }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex items-center flex-col md:flex-row md:mb-7 lg:mb-8">
                <div class="w-full md:w-1/3 lg:w-1/6">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold"> Link 2: </label>
                </div>
                <div class="w-full md:w-2/3 mt-2 md:mt-0">
                  <div class="grid grid-cols-2 gap-4">
                    <div>
                      <Field
                        name="donateUrl.name"
                        v-model="payload.donateUrl.name"
                        type="text"
                        class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-lg text-black-1 dark:text-dark-textPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
                      />
                    </div>

                    <div>
                      <Field
                        name="donateUrl.url"
                        v-model="payload.donateUrl.url"
                        type="text"
                        :class="{
                          'text-danger border border-danger': errors['donateUrl.url'],
                        }"
                        class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-lg text-black-1 dark:text-dark-textPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
                      />
                      <p class="text-danger dark:text-danger text-base italic">
                        {{ errors["donateUrl.url"] }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex items-center flex-col md:flex-row md:mb-7 lg:mb-8">
                <div class="w-full md:w-1/3 lg:w-1/6">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold"> Link 3: </label>
                </div>
                <div class="w-full md:w-2/3 mt-2 md:mt-0">
                  <div class="grid grid-cols-2 gap-4">
                    <div>
                      <Field
                        name="buyTicketUrl.name"
                        v-model="payload.buyTicketUrl.name"
                        type="text"
                        class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-lg text-black-1 dark:text-dark-textPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
                      />
                    </div>

                    <div>
                      <Field
                        name="buyTicketUrl.url"
                        v-model="payload.buyTicketUrl.url"
                        type="text"
                        :class="{
                          'text-danger border border-danger': errors['buyTicketUrl.url'],
                        }"
                        class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-lg text-black-1 dark:text-dark-textPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
                      />
                      <p class="text-danger dark:text-danger text-base italic">
                        {{ errors["buyTicketUrl.url"] }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
                <div class="w-full md:w-1/3 lg:w-1/6">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold"> Breakout Rooms: </label>
                </div>
                <div class="w-full md:w-2/3 mt-2 md:mt-0">
                  <Conference :conferenceList="conferenceData" @onCreate="onCreateConference" @onDelete="onDeleteConference" @onEdit="onEditConference" />
                </div>
              </div>

              <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
                <div class="w-full md:w-1/3 lg:w-1/6">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold"> Video Advertisements: </label>
                </div>
                <div class="w-full md:w-2/3 mt-2 md:mt-0">
                  <p class="font-montserrat text-sm italic mb-4">Currently, we support <b>youtube embed video, vimeo, video.isilive.ca</b></p>
                  <VideoUrl :preVideoList="preVideoData" @onCreate="onCreatePreVideo" @onDelete="onDeletePreVideo" @onEdit="onEditPreVideo" />
                </div>
              </div>

              <div class="relative border-t p-2 pt-4 mt-10 md:mt-2">
                <span class="absolute -top-8 left-2 text-lg font-montserrat font-semibold text-magenta"> Pre-event </span>
                <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
                  <div class="w-full mt-2 md:mt-0 md:w-1/3 lg:w-1/6">
                    <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Background color: </label>
                  </div>
                  <div class="md:flex md:items-center w-2/3 md:w-auto lg:w-2/3 mt-2 md:mt-0">
                    <div class="relative flex items-center">
                      <span class="absolute left-4 text-lg font-semibold text-grey-mid">
                        # &nbsp;
                        {{ payload.preEventConfig.bgColor.substring(1) }}
                        <!-- {{ payload.preEventConfig.bgColor }} -->
                      </span>

                      <span class="absolute left-11.5 w-1px h-12 bg-grey-mid"></span>

                      <Field
                        v-model="payload.preEventConfig.bgColor"
                        name="preEventBackgroundColor"
                        type="color"
                        class="h-12 w-52 md:w-60 text-lg text-left font-semibold appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-grey-dark dark:text-dark-textPrimary border border-grey-mid focus:border-magenta focus:outline-none focus:ring-1 focus:ring-magenta rounded p-0.5 pl-11.75 pr-1"
                      />
                    </div>
                  </div>
                </div>

                <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
                  <div class="w-full mt-2 md:mt-0 md:w-1/3 lg:w-1/6 md:pt-0">
                    <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Background Image: </label>
                  </div>
                  <div class="md:flex md:items-center w-2/3 md:w-auto lg:w-2/3 mt-2 md:mt-0">
                    <label
                      :class="{
                        'py-7': empty(images.preEventBackgroundImage),
                      }"
                      class="w-60 lg:w-60 h-20 lg:h-24 flex flex-col relative items-center bg-off-white-1 dark:bg-dark-bgPrimary text-black-1 dark:text-dark-textPrimary rounded-lg border border-grey-mid focus:ring-magenta focus:border-magenta cursor-pointer md:ml-0"
                    >
                      <QRCodeLogo v-if="empty(images.preEventBackgroundImage)" class="h-8 w-8" />
                      <img v-else :src="images.preEventBackgroundImage" alt="Background Image" class="h-full" />
                      <input name="preEventBackgroundImage" type="file" class="hidden" v-on:change="handleChangeFilesUpload" accept="image/png, image/jpeg" />
                    </label>
                    <template v-if="!empty(images.preEventBackgroundImage)">
                      <button
                        type="button"
                        class="bg-transparent text-lg lg:text-xl text-magenta font-bold inline-flex items-center border-none overflow-hidden focus:outline-none p-2 lg:px-4"
                        @click="handleDeleteFilesUpload('preEventBackgroundImage')"
                      >
                        <DeleteIcon class="w-6 h-6 mr-1 lg:mr-2" />
                        <span>Delete</span>
                      </button>
                    </template>
                  </div>
                </div>

                <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
                  <div class="w-full mt-2 md:mt-0 md:w-1/3 lg:w-1/6 md:pt-0">
                    <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Foreground Image: </label>
                  </div>
                  <div class="md:flex md:items-center w-2/3 md:w-auto lg:w-2/3 mt-2 md:mt-0">
                    <label
                      :class="{
                        'py-7': empty(images.preEventForegroundImage),
                      }"
                      class="w-60 lg:w-60 h-20 lg:h-24 flex flex-col relative items-center bg-off-white-1 dark:bg-dark-bgPrimary text-black-1 dark:text-dark-textPrimary rounded-lg border border-grey-mid focus:ring-magenta focus:border-magenta cursor-pointer md:ml-0"
                    >
                      <QRCodeLogo v-if="empty(images.preEventForegroundImage)" class="h-8 w-8" />
                      <img v-else :src="images.preEventForegroundImage" alt="Background Image" class="h-full" />
                      <input name="preEventForegroundImage" type="file" class="hidden" v-on:change="handleChangeFilesUpload" accept="image/png, image/jpeg" />
                    </label>
                    <template v-if="!empty(images.preEventForegroundImage)">
                      <button
                        type="button"
                        class="bg-transparent text-lg lg:text-xl text-magenta font-bold inline-flex items-center border-none overflow-hidden focus:outline-none p-2 lg:px-4"
                        @click="handleDeleteFilesUpload('preEventForegroundImage')"
                      >
                        <DeleteIcon class="w-6 h-6 mr-1 lg:mr-2" />
                        <span>Delete</span>
                      </button>
                    </template>
                  </div>
                </div>

                <div class="flex items-center flex-col md:flex-row md:mb-7 lg:mb-8">
                  <div class="w-1/2 md:w-1/3 lg:w-1/4">
                    <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Message: </label>
                  </div>

                  <div class="w-full md:w-2/3 mt-2 md:mt-0">
                    <Field
                      v-model="payload.preEventConfig.text"
                      name="preEventMessage"
                      as="textarea"
                      :class="{
                        'text-danger border border-danger': errors['preEventMessage'],
                      }"
                      class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
                    />
                    <p class="text-danger dark:text-danger text-base italic">
                      {{ errors["preEventMessage"] }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="relative border-t p-2 pt-4 mt-8 md:mt-0">
                <span class="absolute -top-8 left-2 text-lg font-montserrat font-semibold text-magenta"> Post-event </span>
                <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
                  <div class="w-full mt-2 md:mt-0 md:w-1/3 lg:w-1/6">
                    <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Background color: </label>
                  </div>
                  <div class="md:flex md:items-center w-2/3 md:w-auto lg:w-2/3 mt-2 md:mt-0">
                    <div class="relative flex items-center">
                      <span class="absolute left-4 text-lg font-semibold text-grey-mid">
                        # &nbsp;
                        {{ payload.postEventConfig.bgColor.substring(1) }}
                        <!-- {{ payload.postEventBackgroundColor }} -->
                      </span>

                      <span class="absolute left-11.5 w-1px h-12 bg-grey-mid"></span>

                      <Field
                        v-model="payload.postEventConfig.bgColor"
                        name="postEventBackgroundColor"
                        type="color"
                        class="h-12 w-52 md:w-60 text-lg text-left font-semibold appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-grey-dark dark:text-dark-textPrimary border border-grey-mid focus:border-magenta focus:outline-none focus:ring-1 focus:ring-magenta rounded p-0.5 pl-11.75 pr-1"
                      />
                    </div>
                  </div>
                </div>

                <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
                  <div class="w-full mt-2 md:mt-0 md:w-1/3 lg:w-1/6 md:pt-0">
                    <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Background Image: </label>
                  </div>
                  <div class="md:flex md:items-center w-2/3 md:w-auto lg:w-2/3 mt-2 md:mt-0">
                    <label
                      :class="{
                        'py-7': empty(images.postEventBackgroundImage),
                      }"
                      class="w-60 lg:w-60 h-20 lg:h-24 flex flex-col relative items-center bg-off-white-1 dark:bg-dark-bgPrimary text-black-1 dark:text-dark-textPrimary rounded-lg border border-grey-mid focus:ring-magenta focus:border-magenta cursor-pointer md:ml-0"
                    >
                      <QRCodeLogo v-if="empty(images.postEventBackgroundImage)" class="h-8 w-8" />
                      <img v-else :src="images.postEventBackgroundImage" alt="Background Image" class="h-full" />
                      <input name="postEventBackgroundImage" type="file" class="hidden" v-on:change="handleChangeFilesUpload" accept="image/png, image/jpeg" />
                    </label>
                    <template v-if="!empty(images.postEventBackgroundImage)">
                      <button
                        type="button"
                        class="bg-transparent text-lg lg:text-xl text-magenta font-bold inline-flex items-center border-none overflow-hidden focus:outline-none p-2 lg:px-4"
                        @click="handleDeleteFilesUpload('postEventBackgroundImage')"
                      >
                        <DeleteIcon class="w-6 h-6 mr-1 lg:mr-2" />
                        <span>Delete</span>
                      </button>
                    </template>
                  </div>
                </div>

                <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
                  <div class="w-full mt-2 md:mt-0 md:w-1/3 lg:w-1/6 md:pt-0">
                    <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Foreground Image: </label>
                  </div>
                  <div class="md:flex md:items-center w-2/3 md:w-auto lg:w-2/3 mt-2 md:mt-0">
                    <label
                      :class="{
                        'py-7': empty(images.postEventForegroundImage),
                      }"
                      class="w-60 lg:w-60 h-20 lg:h-24 flex flex-col relative items-center bg-off-white-1 dark:bg-dark-bgPrimary text-black-1 dark:text-dark-textPrimary rounded-lg border border-grey-mid focus:ring-magenta focus:border-magenta cursor-pointer md:ml-0"
                    >
                      <QRCodeLogo v-if="empty(images.postEventForegroundImage)" class="h-8 w-8" />
                      <img v-else :src="images.postEventForegroundImage" alt="Background Image" class="h-full" />
                      <input name="postEventForegroundImage" type="file" class="hidden" v-on:change="handleChangeFilesUpload" accept="image/png, image/jpeg" />
                    </label>
                    <template v-if="!empty(images.postEventForegroundImage)">
                      <button
                        type="button"
                        class="bg-transparent text-lg lg:text-xl text-magenta font-bold inline-flex items-center border-none overflow-hidden focus:outline-none p-2 lg:px-4"
                        @click="handleDeleteFilesUpload('postEventForegroundImage')"
                      >
                        <DeleteIcon class="w-6 h-6 mr-1 lg:mr-2" />
                        <span>Delete</span>
                      </button>
                    </template>
                  </div>
                </div>

                <div class="flex items-center flex-col md:flex-row md:mb-7 lg:mb-8">
                  <div class="w-1/2 md:w-1/3 lg:w-1/4">
                    <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Message: </label>
                  </div>

                  <div class="w-full md:w-2/3 mt-2 md:mt-0">
                    <Field
                      v-model="payload.postEventConfig.text"
                      name="postEventMessage"
                      as="textarea"
                      :class="{
                        'text-danger border border-danger': errors['postEventMessage'],
                      }"
                      class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
                    />
                    <p class="text-danger dark:text-danger text-base italic">
                      {{ errors["postEventMessage"] }}
                    </p>
                  </div>
                </div>
              </div>

              <button
                type="submit"
                class="button_large-Alternative absolute right-0 bottom-0 md:bottom-auto md:-top-4 md:-right-4 uppercase bg-magenta border rounded-full py-3 lg:py-4 lg:px-8 focus:outline-none mb-4 md:mb-0"
              >
                Create
              </button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { reactive, ref, onBeforeMount, watch } from "vue";

import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import moment from "moment";
import { getMomentT, formatDate } from "@Helpers/date";
import * as _ from "lodash";
import axios from "axios";

import Conference from "@/components/pages/event-detail/Conference";
import VideoUrl from "@/components/pages/event-detail/VideoUrl";

import { sleep } from "@Helpers/sleep";
import { postEvent, getS3UploadKey } from "@API/event/event-repository";
import { getEventTemplates } from "@API/event/template-repository";

import XLogo from "@Assets/images/XLogo";
import DeleteIcon from "@Assets/images/DeleteIcon";
import AddIcon from "@Assets/images/AddIcon";
import QRCodeLogo from "@Assets/images/QRCodeLogo";
import Switch from "@/components/layouts/Switch.vue";
import ComboBox from "@/components/layouts/ComboBox";

import { empty, isFile } from "@/utils";
export default {
  name: "NewEvent",
  components: {
    Form,
    Field,
    XLogo,
    DeleteIcon,
    AddIcon,
    QRCodeLogo,
    Conference,
    Switch,
    ComboBox,
    VideoUrl,
  },
  setup() {
    const payload = reactive({
      id: undefined,
      name: "",
      dateTime: undefined,
      welcomeMessage: "",
      customMessage: "",
      virtualEventUrl: {
        name: "",
        url: "",
      },
      fundraisingSiteUrl: {
        // name: "",
        url: "",
      },
      charityLogo: "",
      sponsorLogo: [],
      backgroundImage: "",
      primaryColour: "#ffffff",
      secondaryColor: "#ffffff",
      font: "",
      brandGuidelines: "",
      bidUrl: {
        name: "",
        url: "",
      },
      donateUrl: {
        name: "",
        url: "",
      },
      buyTicketUrl: {
        name: "",
        url: "",
      },
      leaderBoardUrl: {
        name: "",
        url: "",
      },
      activityTickerUrl: {
        name: "",
        url: "",
      },
      totalIserTickerUrl: {
        name: "",
        url: "",
      },
      qrCode: "",
      preEventConfig: {
        bgColor: "#ffffff",
        bgImage: "",
        bgForegroundImage: "",
        text: "",
      },
      postEventConfig: {
        bgColor: "#ffffff",
        bgImage: "",
        bgForegroundImage: "",
        text: "",
      },
      preVideos: [],
    });
    const data = reactive({
      currentFont: "",
      isOpenDropdown: false,
      date: formatDate(null, "yyyy-MM-DD"),
      isPaidEvent: false,
      price: 0,
      loginMode: "PUBLIC",
      eventTemplateList: [],
      eventTemplateId: 0,
    });
    const settings = reactive({
      isProtect: false,
      password: "",
      bottomBar: true,
    });

    onBeforeMount(getTemplates);

    async function getTemplates() {
      try {
        const resp = await getEventTemplates();
        data.eventTemplateList = resp.data;
      } catch (error) {
        console.log({ error });
      }
    }

    watch(
      () => data.eventTemplateId,
      value => {
        const eventTemplate = _.find(data.eventTemplateList, item => item.id === value.value);
        const { primaryColour, secondaryColor, font, preEventConfig, postEventConfig, customCSS, settings: settingTemmplate, loginMode, price, isPaidEvent } = eventTemplate;
        payload.primaryColour = primaryColour;
        payload.secondaryColor = secondaryColor;
        payload.preEventConfig = preEventConfig;
        payload.postEventConfig = postEventConfig;
        payload.customCSS = customCSS;
        data.currentFont = font;
        data.isPaidEvent = isPaidEvent;
        data.price = price;
        data.loginMode = loginMode;
        settings.isProtect = settingTemmplate.isProtect;
        settings.password = settingTemmplate.password;
        settings.bottomBar = settingTemmplate.bottomBar === "SHOW";
      },
    );

    const conferenceData = reactive([]);
    const preVideoData = reactive([]);
    onCreateConference();
    onCreatePreVideo();

    function onCreateConference() {
      _.forEach(conferenceData, (item, index) => {
        conferenceData[index] = {
          ...item,
          isEdit: false,
        };
      });
      conferenceData.push({
        url: "",
        title: "",
        pinCode: "",
        isNew: true,
        isEdit: true,
      });
    }
    function onDeleteConference(conferenceIndex) {
      conferenceData.splice(conferenceIndex, 1);
      if (empty(conferenceData)) {
        onCreateConference();
      }
    }

    function onEditConference([conferenceIndex, data]) {
      conferenceData[conferenceIndex] = {
        ...data,
        isEdit: !data.isEdit,
      };
    }

    function onCreatePreVideo() {
      _.forEach(preVideoData, (item, index) => {
        preVideoData[index] = {
          ...item,
          isEdit: false,
        };
      });
      preVideoData.push({
        url: "",
        isNew: true,
        isEdit: true,
      });
    }
    function onDeletePreVideo(preVideoIndex) {
      preVideoData.splice(preVideoIndex, 1);
      if (empty(preVideoData)) {
        onCreatePreVideo();
      }
    }

    function onEditPreVideo([preVideoIndex, data]) {
      preVideoData[preVideoIndex] = {
        ...data,
        isEdit: !data.isEdit,
      };
    }

    let dropdown = ref(null);
    const schema = Yup.object().shape({
      name: Yup.string().required("Event name is required"),
      eventDate: Yup.string().required("Event date is required"),
      welcomeMessage: Yup.string().required("Welcome message is required"),
      youtubeURL: Yup.string().required("Streaming URL is required"),
      password: Yup.lazy(() => (settings.isProtect ? Yup.string().required("password is required") : Yup.string())),
      price: Yup.lazy(() => (data.isPaidEvent ? Yup.string().required("Price is required") : Yup.string())),
      fundraisingSiteUrl: Yup.object().shape({
        url: Yup.string().url("Enter correct url").nullable(),
      }),
      bidUrl: Yup.object().shape({
        url: Yup.string().url("Enter correct url").nullable(),
      }),
      donateUrl: Yup.object().shape({
        url: Yup.string().url("Enter correct url").nullable(),
      }),
      buyTicketUrl: Yup.object().shape({
        url: Yup.string().url("Enter correct url").nullable(),
      }),
    });

    return {
      settings,
      data,
      dropdown,
      schema,
      conferenceData,
      onCreateConference,
      onDeleteConference,
      onEditConference,
      preVideoData,
      onCreatePreVideo,
      onDeletePreVideo,
      onEditPreVideo,
      payload,
    };
  },
  data() {
    return {
      filesUpload: {
        charityLogo: null,
        sponsorLogo: [],
        backgroundImage: null,
        preEventBackgroundImage: null,
        preEventForegroundImage: null,
        postEventBackgroundImage: null,
        postEventForegroundImage: null,
      },
      images: {
        charityLogo: null,
        sponsorLogo: [],
        backgroundImage: null,
        preEventBackgroundImage: null,
        preEventForegroundImage: null,
        postEventBackgroundImage: null,
        postEventForegroundImage: null,
      },
      imagesPayload: {
        charityLogo: null,
        sponsorLogo: [],
        backgroundImage: null,
        preEventBackgroundImage: null,
        preEventForegroundImage: null,
        postEventBackgroundImage: null,
        postEventForegroundImage: null,
      },
    };
  },

  methods: {
    empty,
    isFile,
    handleDeleteFilesUpload(property, index) {
      if (property === "sponsorLogo") {
        this.filesUpload.sponsorLogo.splice(index, 1);
        this.images.sponsorLogo.splice(index, 1);
      } else {
        this.filesUpload[property] = null;
        this.images[property] = null;
      }
    },
    handleChangeFilesUpload(e, index) {
      const fileSelected = e.target.files[0];
      let reader = new FileReader();
      reader.onload = event => {
        if (e.target.name !== "sponsorLogo") {
          this.filesUpload[e.target.name] = fileSelected;
          this.images[e.target.name] = event.target.result;
        } else {
          if (index > -1) {
            this.filesUpload.sponsorLogo[index] = fileSelected;
            this.images.sponsorLogo[index] = event.target.result;
          } else {
            this.filesUpload.sponsorLogo.push(fileSelected);
            this.images.sponsorLogo.push(event.target.result);
          }
        }
      };
      reader.readAsDataURL(fileSelected);
    },
    async doUpdateFile(file, property) {
      if (!file.type.includes("image/")) return;

      const s3Result = await getS3UploadKey(file.type.replace("image/", ""));

      const {
        data: { fields, url },
      } = s3Result;

      const formData = new FormData();

      Object.keys(fields).forEach(key => {
        formData.append(key, fields[key]);
      });
      // Actual file has to be appended last.
      formData.append("file", file);

      const result = await axios.post(url, formData, {});

      if (result.status === 204) {
        if (property === "sponsorLogo") {
          this.imagesPayload[property] = this.imagesPayload[property] || [];
          this.imagesPayload[property].push(fields.key);
        } else {
          this.imagesPayload[property] = fields.key;
        }
      }

      // Show Alert
      this.$store.dispatch("toggleAlert", {
        isAlert: true,
        status: result.status === 204,
        // title: result.status === 204 ? "Success!" : "Failed!",
        // message: `[${_.startCase(_.camelCase(property))}] ${
        //   result.status === 204
        //     ? "Upload file successfully!"
        //     : "Upload file failed!"
        // }`,
        title: `[${result.status === 204 ? "Success!" : "Failed!"}] ${_.startCase(_.camelCase(property))}`,
        message: result.status === 204 ? "Upload file successfully!" : "Upload file failed!",
      });

      await sleep(1600);

      // Hide Alert
      this.$store.dispatch("toggleAlert", {
        isAlert: false,
      });
    },
    async handleUploadFiles(property) {
      // this.$store.dispatch("toggleLoading");
      this.$store.dispatch("updateLoadingText", "Uploading " + _.startCase(_.camelCase(property)));
      if (property === "sponsorLogo") {
        const fileUploads = this.filesUpload[property];
        for (let i = 0; i < fileUploads.length; i++) {
          await this.doUpdateFile(fileUploads[i], property);
        }
      } else {
        await this.doUpdateFile(this.filesUpload[property], property);
      }
      // this.$store.dispatch("toggleLoading");
    },
    async onSubmit() {
      try {
        this.$store.dispatch("toggleLoading");
        const payload = {
          ...this.payload,
          dateTime: getMomentT(this.data.date).format(),
          font: this.data.currentFont,
          settings: {
            ...this.settings,
            bottomBar: this.settings.bottomBar ? "SHOW" : "HIDE",
          },
          loginMode: this.data.loginMode,
          options: _.map(this.conferenceData, item => _.omit(item, ["isNew", "isEdit"])),
          preVideos: _.map(
            _.filter(this.preVideoData, item => !empty(item.url)),
            item => item.url,
          ),
          price: this.data.isPaidEvent ? this.data.price : 0,
          isPaidEvent: this.data.isPaidEvent,
        };

        _.forOwn(payload, (value, key) => {
          if (key.includes("Url") && !value.url.length) delete payload[key];
        });
        const filesUploadKeys = _.keys(this.filesUpload);

        for (let i = 0; i < filesUploadKeys.length; i++) {
          if (isFile(this.filesUpload[filesUploadKeys[i]]) || !empty(this.filesUpload[filesUploadKeys[i]])) {
            await this.handleUploadFiles(filesUploadKeys[i]);
          }
        }

        const dataSubmit = {
          ...payload,
          preEventConfig: {
            ...payload.preEventConfig,
            bgImage: this.imagesPayload.preEventBackgroundImage,
            bgForegroundImage: this.imagesPayload.preEventForegroundImage,
          },
          postEventConfig: {
            ...payload.postEventConfig,
            bgImage: this.imagesPayload.postEventBackgroundImage,
            bgForegroundImage: this.imagesPayload.postEventForegroundImage,
          },
        };

        delete this.imagesPayload.preEventBackgroundImage;
        delete this.imagesPayload.preEventForegroundImage;
        delete this.imagesPayload.postEventBackgroundImage;
        delete this.imagesPayload.postEventForegroundImage;

        this.$store.dispatch("updateLoadingText", "Creating new event");
        const result = await postEvent({
          ...dataSubmit,
          ...this.imagesPayload,
        });

        // Show Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: true,
          status: result.success,
          title: result.success ? "Success!" : "Failed!",
          message: result.message,
        });

        if (result.success) {
          this.$router.push({
            name: "Template",
          });
        }
      } catch (error) {
        console.log({ error });
      } finally {
        this.$store.dispatch("closeLoading");

        await sleep(5000);
        // Hide Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: false,
        });
      }
    },
  },
};
</script>
